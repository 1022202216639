
import { Vue, Options, prop } from "vue-class-component";

class Props {
  disabled = prop<boolean>({
    default: false,
    type: Boolean,
  });
  placeholder = prop<string>({
    default: "",
    type: String,
  });
  titlePickup = prop<string>({
    default: "Pilih jam Pick Up",
    type: String,
  });
  interval = prop<number>({
    default: 60,
    type: Number,
  });
  modelValue = prop<any>({
    required: true,
  });
  maxHeight = prop<number>({
    default: 300,
    required: false
  })
}
@Options({
  emits: ["onSelect", "onRemove"],
})
export default class TimeRangePickerV2 extends Vue.with(Props) {
  isMenu = false;
  times: any = [];
  optimizedHeight = this.maxHeight;
  preferredOpenDirection = "below";

  get value() {
    return this.modelValue;
  }

  set value(val) {
    this.$emit("update:modelValue", val);
  }

  mounted() {
    this.onSetItems();
  }

  onSelectTime(item: any) {
    this.value = item;
    this.$emit("onSelect");
    if (this.value) this.showMenu();
  }

  onClear() {
    this.value = "";
    this.$emit("onRemove");
  }

  onSetItems() {
    const result = [];
    for (let i = 0; i <= 60 * 24; i += this.interval) {
      const hours = Math.floor(i / 60);
      const minutes = i % 60;
      result.push(
        `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`
      );
    }
    this.times = result.slice(0, -1);
  }
  showMenu() {
    this.isMenu = !this.isMenu;
    if (this.isMenu) {
      this.adjustPosition();
    }
  }

  isSelected() {
    return this.times.findIndex((element: any) => element === this.value);
  }

  private adjustPosition(): void {
    if (typeof window === "undefined") return;

    const spaceAbove = this.$el.getBoundingClientRect().top;
    const spaceBelow =
      window.innerHeight - this.$el.getBoundingClientRect().bottom;
    const hasEnoughSpaceBelow = spaceBelow > this.maxHeight;

    if (hasEnoughSpaceBelow || spaceBelow > spaceAbove) {
      this.preferredOpenDirection = "below";
      this.optimizedHeight = Math.min(spaceBelow - 40, this.maxHeight);
    } else {
      this.preferredOpenDirection = "above";
      this.optimizedHeight = Math.min(spaceAbove - 40, this.maxHeight);
    }
  }

  get isAbove() {
    return this.preferredOpenDirection === "above";
  }
}
